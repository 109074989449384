import React from 'react';
import { Container } from 'reactstrap';
import { Route, Switch, Redirect } from 'react-router-dom';
import Upload from '../Upload/index';
import Patients from '../Patients/index';
import Orders from '../Orders';
import Navigationbar from './components/Navigationbar';
import DashboardVariants from '../Content/components/DashboardVariants';
import Haplotypes from '../Content/components/Haplotypes';
import Phenotypes from '../Content/components/Phenotypes';
import Variants from '../Content/components/Variants';
const Main = () => (
  <>
    <Navigationbar />
    <Container className="app">
      <main className="content">
        <Switch>
          <Route exact path="/" render={() => <Redirect to="/upload" />} />
          <Route exact path="/upload" component={Upload} />
          <Route exact path="/patients" component={Patients} />
          <Route exact path="/orders" component={Orders} />
          <Route exact path="/dashboard_variants" component={DashboardVariants} />
          <Route exact path="/haplotypes" component={Haplotypes} />
          <Route exact path="/phenotypes" component={Phenotypes} />
          <Route exact path="/variants" component={Variants} />
          <Route render={() => <Redirect to="/" />} />
        </Switch>
      </main>
    </Container>
  </>
);

export default Main;
