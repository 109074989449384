import React from "react";
import { Row, Col } from "reactstrap";
import logo from "../../images/biologis_gim.svg";
import support from "../../images/support.svg";
import manual from "../../images/manual.svg";
import { FormattedMessage } from "react-intl";

const Footer = () => (
  <Row className="footer">
    <Col className="d-flex">
      <a
        href="https://www.biologis.com/contact"
        target="blank"
        className="support"
      >
        <img src={support} alt="support" />
        <FormattedMessage id="footer.support" />
      </a>

      <a
        href={`${process.env.PUBLIC_URL}/GIMS-SSUP-Manual.pdf`}
        target="blank"
        className="manual"
      >
        <img src={manual} alt="manual" />
        <FormattedMessage id="footer.manual" />
      </a>
    </Col>
    <Col>
      <img src={logo} className="gim-logo float-right" alt="logo" />
    </Col>
  </Row>
);

export default Footer;
