import React, { Component } from 'react';
import { Table, FormGroup, Button } from 'reactstrap';
import { PropTypes } from 'prop-types';
import { SchemaForm, Modal } from '@brightsolutionsgmbh/biologis-react-components';
import { FormattedMessage, injectIntl, intlShape } from 'react-intl';
import dayjs from 'dayjs';
import DownloadButton from '../DownloadButton';

export class FinalizeReportTable extends Component {
  static pathToIcons = `${
    process.env.REACT_APP_SERVER_ROOT
  }/modules/custom/as_clinical_report_order_service/icons`;

  getIcon = status => {
    switch (status) {
      case 'ready':
      case 'report_delivered':
        return `${FinalizeReportTable.pathToIcons}/report_delivered.svg`;
      case 'pending':
      case 'rle_pending':
        return `${FinalizeReportTable.pathToIcons}/pending.svg`;
      case 'error':
      case 'error_delivered':
      case 'rle_error':
      case 'processing_time_limit_exceeded':
        return `${FinalizeReportTable.pathToIcons}/error.svg`;
      default:
        return '';
    }
  };

  getGenerationStatusMessageString = status => {
    switch (status) {
      case 'report_delivered':
      case 'ready':
        return `generationStatusReady`;
      case 'pending':
        return `generationStatusPending`;
      case 'waiting_for_input':
        return `generationStatusWaitingForInput`;
      case 'error':
      case 'error_delivered':
        return `generationStatusReportError`;
      default:
        return '';
    }
  };

  getPersonalizationStatusMessageString = status => {
    switch (status) {
      case 'report_delivered':
        return `personalizationStatusReportDelivered`;
      case 'rle_pending':
      case 'pending':
        return `personalizationStatusPending`;
      case 'rle_error':
        return `personalizationStatusError`;
      default:
        return '';
    }
  };

  render() {
    const {
      orders,
      contentVariables,
      questionnaire,
      getQuestions,
      getContentVariables,
      submitQuestions,
      submitContentVariables,
      downloadFile,
      intl
    } = this.props;

    const renderButtons = (
      { values, isSubmitting, errors, formFields },
      order,
      toggleModal
    ) => {
      let hideSendButton = false;

      Object.keys(formFields).forEach(formFieldKey => {
        formFields[formFieldKey].questions.forEach(question => {
          Object.keys(values).forEach(() => {
            if (question.required) {
              if (
                values[question.name] === '' ||
                values[question.name] === false
              ) {
                hideSendButton = true;
              }
            }
          });
        });
      });

      return (
        <FormGroup>
          <Button
            color="primary"
            onClick={() => submitQuestions(values, order, toggleModal)}
          >
            <FormattedMessage id="orderList.questionModal.saveButton" />
          </Button>
          {!hideSendButton && (
            <Button
              color="primary"
              className="mx-2"
              onClick={() => submitQuestions(values, order, toggleModal, true)}
            >
              <FormattedMessage id="orderList.questionModal.sendButton" />
            </Button>
          )}
        </FormGroup>
      );
    };

    return (
      <Table striped>
        <thead>
          <tr>
            <th rowSpan="2">
              <FormattedMessage id="orderList.patientId" />
            </th>
            <th rowSpan="2">
              <FormattedMessage id="orderList.requestDate" />
            </th>
            <th rowSpan="2">
              <FormattedMessage id="orderList.generationStatus" />
            </th>
            <th rowSpan="2">
              <FormattedMessage id="orderList.personalization" />
            </th>
            <th rowSpan="2">
              <FormattedMessage id="orderList.personalizationStatus" />
            </th>
            <th colSpan="4" className="download-header">
              <FormattedMessage id="orderList.download" />
            </th>
          </tr>
          <tr className="download-sub-header">
            <th>
              <FormattedMessage id="orderList.report" />
            </th>
            <th>
              <FormattedMessage id="orderList.safetyCard" />
            </th>
            <th>
              <FormattedMessage id="orderList.coverLetter" />
            </th>
          </tr>
        </thead>
        <tbody>
          {orders.data &&
            orders.data.map((order, index) => (
              <tr key={index}>
                <td>
                  {order.attributes.patient_id}
                  <span className="report-description">
                    {order.attributes.clinical_report} (
                    <FormattedMessage
                      id={`orderList.langCode.${order.attributes.langcode}`}
                    />
                    )
                  </span>
                </td>
                <td>
                  {dayjs(order.attributes.request_timestamp).format(
                    'YYYY-MM-DD HH:mm:ss'
                  )}
                </td>
                <td>
                  <img
                    className="icon"
                    alt=""
                    src={this.getIcon(
                      order.attributes.combined_processing_status
                    )}
                  />
                  <FormattedMessage
                    id={`orderList.${this.getGenerationStatusMessageString(
                      order.attributes.combined_processing_status
                    )}`}
                  />
                </td>
                <td>
                  {order.attributes.combined_processing_status ===
                  'waiting_for_input' ? (
                    <Modal
                      onModalOpen={toggle => getQuestions(order, toggle)}
                      render={toggle => (
                        <SchemaForm
                          submit={values =>
                            submitQuestions(values, order, toggle)
                          }
                          formFields={questionnaire}
                          renderButtons={props =>
                            renderButtons(props, order, toggle)
                          }
                        />
                      )}
                      withFooter={false}
                      buttonColor="primary"
                      buttonLabel={intl.formatMessage({
                        id: 'orderList.inputQuestionsButton'
                      })}
                    />
                  ) : (
                    <Modal
                      onModalOpen={toggle => getContentVariables(order, toggle)}
                      render={toggle => (
                        <SchemaForm
                          submit={(values, actions) =>
                            submitContentVariables(
                              values,
                              actions,
                              order,
                              toggle
                            )
                          }
                          formFields={contentVariables}
                        />
                      )}
                      disableButton={
                        order.attributes.combined_processing_status !==
                          'report_delivered' ||
                        order.attributes
                          .print_job_combined_processing_status === 'pending' ||
                        order.attributes
                          .print_job_combined_processing_status ===
                          'rle_pending'
                      }
                      withFooter={false}
                      buttonColor="primary"
                      buttonLabel={intl.formatMessage({
                        id: 'orderList.inputContentVariableButton'
                      })}
                      title={`${intl.formatMessage({
                        id: 'orderList.patientId'
                      })}: ${order.attributes.patient_id}`}
                    />
                  )}
                </td>
                <td>
                  {order.attributes.print_job_combined_processing_status && (
                    <>
                      <img
                        className="icon"
                        alt=""
                        src={this.getIcon(
                          order.attributes.print_job_combined_processing_status
                        )}
                      />
                      <FormattedMessage
                        id={`orderList.${this.getPersonalizationStatusMessageString(
                          order.attributes.print_job_combined_processing_status
                        )}`}
                      />
                    </>
                  )}
                </td>
                <td>
                  {order.attributes.print_job_download_urls[0] &&
                    order.attributes.print_job_download_urls[0].links.map(
                      (item, i) => (
                        <DownloadButton
                          key={i}
                          item={item}
                          downloadFile={downloadFile}
                        />
                      )
                    )}
                </td>
                <td>
                  {order.attributes.print_job_download_urls[1] &&
                    order.attributes.print_job_download_urls[1].links.map(
                      (item, i) => (
                        <DownloadButton
                          key={i}
                          item={item}
                          downloadFile={downloadFile}
                        />
                      )
                    )}
                </td>
                <td>
                  {order.attributes.print_job_download_urls[2] &&
                    order.attributes.print_job_download_urls[2].links.map(
                      (item, i) => (
                        <DownloadButton
                          key={i}
                          item={item}
                          downloadFile={downloadFile}
                        />
                      )
                    )}
                </td>
              </tr>
            ))}
        </tbody>
      </Table>
    );
  }
}

FinalizeReportTable.propTypes = {
  intl: intlShape.isRequired,
  orders: PropTypes.object,
  contentVariables: PropTypes.object,
  questionnaire: PropTypes.object,
  getQuestions: PropTypes.func.isRequired,
  getContentVariables: PropTypes.func.isRequired,
  submitContentVariables: PropTypes.func.isRequired,
  submitQuestions: PropTypes.func.isRequired,
  downloadFile: PropTypes.func.isRequired
};

export default injectIntl(FinalizeReportTable);
