import React, { Component } from 'react';
import { Container, Row, Col, Button, Card, CardHeader, CardBody, CardFooter, Table, FormText, Label, ButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import "../../../../../../styles/content.scss";

export class ManualVariantEntryEditor extends Component {
  
    constructor(props) {
      super(props);
  
      this.state = {
        actionsDropdown: false,
        variants: ['1'],
      };
    }

    toggle = () => {
        this.setState({ actionsDropdown: !this.state.actionsDropdown })
    }

    render () {
    const { closeEditing } = this.props;

    const validationSchema = Yup.object().shape({
        variantName: Yup.string()
            .required('Required!')
    })

    return (
        <Formik
            initialValues={{variantName: '', transcript: ''}}
            validationSchema={validationSchema}
            render={({ values }) => (
                <Container className='mt-5'>
                    <Card>
                        <CardHeader>
                            Enter new variant
                        </CardHeader>
                        <CardBody>
                            <Container>
                                <Row>
                                    <Col>
                                        <Row>
                                            <Col xs='3'>
                                                <Label>
                                                    Name:
                                                </Label>
                                            </Col>
                                            <Col xs='8'>
                                                <Form>
                                                    <Field name='variantName' className='formik-field' />
                                                    <ErrorMessage name='variantName' />
                                                </Form>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col sm={{size: 8, offset: 3}}>
                                                <FormText>
                                                    According to HGVS nomenclature
                                                </FormText>
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col>
                                        {!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.variantName) ? (
                                            null
                                        ) : (
                                            <Row>
                                                <Col xs='3'>
                                                    <Label>
                                                        Transcript:
                                                    </Label>
                                                </Col>
                                                <Col xs='8'>
                                                    <Form>
                                                        <Field name='transcript' component='select' className='formik-select'>
                                                            <option value=''>Choose transcript...</option>
                                                            <option value='ABC'>ABC</option>
                                                            <option value='XYZ'>XYZ</option>
                                                        </Field>
                                                    </Form>
                                                </Col>
                                            </Row>
                                        )}
                                    </Col>
                                </Row>
                                { values.transcript && values.transcript !== '' ? (
                                    <div>
                                        <Row className='mt-5'>
                                            <Col xs='4'>
                                                <Label>
                                                    Variant:
                                                </Label>
                                            </Col>
                                            <Col xs='7'>
                                                {this.state.variants && this.state.variants.length > 0 ? (
                                                    <Table>
                                                        <thead>
                                                            <tr>
                                                                <th>gene</th>
                                                                <th>cDNA</th>
                                                                <th>protein</th>
                                                                <th>rs ID</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {this.state.variants.map((variant, index) => (
                                                                <tr key={index}>
                                                                    <td>A</td>
                                                                    <td>B</td>
                                                                    <td>C</td>
                                                                    <td>D</td>
                                                                </tr>
                                                            ))}
                                                        </tbody>
                                                    </Table>) : (
                                                        <Col sm={{size: 4}}>
                                                            No variants found!
                                                        </Col>
                                                    )}
                                            </Col>
                                        </Row>
                                        <Row className='mt-3'>
                                            <Col xs='4'>
                                                <Label>
                                                    Custom allele name (optional)
                                                </Label>
                                            </Col>
                                            <Col xs='7'>
                                                <Form>
                                                    <Field name='customAlleleName' className='formik-field'/>
                                                </Form>
                                            </Col>
                                        </Row>
                                        <Row className='mt-3'>
                                            <Col xs='4'>
                                                <Label>
                                                    Variant classification
                                                </Label>
                                            </Col>
                                            <Col xs='7'>
                                                <Button size='sm' color='light' className='button-large' onClick={() => console.log('Classify')}>Classify</Button>
                                            </Col>
                                        </Row>
                                    </div>
                                ) : (
                                    null
                                )}
                            </Container>
                        </CardBody>
                        <CardFooter>
                            <Row>
                                <Col xs='2'>
                                    <Button size='sm' color='primary' className='button-large' onClick={closeEditing}>
                                        Back
                                    </Button>
                                </Col>
                                <Col sm={{size: 2, offset: 6}}>
                                    <ButtonDropdown isOpen={this.state.actionsDropdown} toggle={this.toggle} className='button-large'>
                                        <DropdownToggle size='sm' color='primary' caret>
                                            Actions
                                        </DropdownToggle>
                                        <DropdownMenu>
                                            <DropdownItem>Enter new variant</DropdownItem>
                                            <DropdownItem>Manage haplotypes</DropdownItem>
                                            <DropdownItem>Link to active ingredient</DropdownItem>
                                        </DropdownMenu>
                                    </ButtonDropdown>
                                </Col>
                                <Col xs='2'>
                                    <Button size='sm' color='primary' className='button-large' onClick={closeEditing} disabled={true}>
                                        Save
                                    </Button>
                                </Col>
                            </Row>
                        </CardFooter>
                    </Card>
                </Container>
            )} />
        )
    }
}

export default ManualVariantEntryEditor