import React, { Component } from 'react';
import { Container, Form, FormGroup, Input, Button } from 'reactstrap';

class HaplotypeFilter extends Component {
  constructor(props) {
    super(props);

    this.state = {
      haplotypeId: '',
    };
  }

  render() {
    const { haplotypeId }  = this.state;
    const { handleSubmit } = this.props;

    return (
      <Container>
        <Form
          onSubmit={e => {
            e.preventDefault();
            handleSubmit( {haplotypeId} );
          }}
          inline
        >
          <FormGroup>
            <Input
              type="text"
              name="haplotypeId"
              placeholder="Search Id / name"
              value={haplotypeId}
              onChange={e =>
                this.setState({
                  haplotypeId: e.target.value
                })
              }
            />
          </FormGroup>
          <Button type="submit" color="primary">
            Search
          </Button>
        </Form>
      </Container>
    );
  }
}

export default HaplotypeFilter;
