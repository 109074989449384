import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Route, BrowserRouter, Switch } from 'react-router-dom';
import { addLocaleData, IntlProvider } from 'react-intl';
import { withAuth } from '@brightsolutionsgmbh/biologis-react-components';
import { Container } from 'reactstrap';
import LogIn from '../LogIn';
import Main from '../Main';
import LogOut from './components/LogOut';
import flattenMessages from '../../utils/flattenMessages';
import de from 'react-intl/locale-data/de';
import en from 'react-intl/locale-data/en';
import messages from '../../messages';
import Footer from '../../components/Footer';
import '../../styles/App.scss';

addLocaleData([...de, ...en]);

export class App extends Component {
  render() {
    const localeData = messages['en'];

    return (
      <IntlProvider locale="en" messages={flattenMessages(localeData)}>
        <BrowserRouter basename={process.env.REACT_APP_BASENAME || '/'}>
          <Container
            className="d-flex flex-column justify-content-between h-100"
            fluid
          >
            <Switch>
              <Route path="/login" component={LogIn} />
              <Route
                path="/logout"
                component={withAuth({ isLoggedIn: this.props.isLoggedIn })(
                  LogOut
                )}
              />
              <Route
                path="/"
                component={withAuth({ isLoggedIn: this.props.isLoggedIn })(
                  Main
                )}
              />
            </Switch>
            <Footer />
          </Container>
        </BrowserRouter>
      </IntlProvider>
    );
  }
}

App.propTypes = {
  isLoggedIn: PropTypes.bool.isRequired
};

const mapStateToProps = state => {
  return {
    isLoggedIn: state.auth.isLoggedIn
  };
};

export default connect(mapStateToProps)(App);
