import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { LogOut as LogOutComponent } from '@brightsolutionsgmbh/biologis-react-components';
import { apiService } from '../../../../services';

export class LogOut extends Component {
  render() {
    const { logOut } = this.props;

    return <LogOutComponent logOut={logOut} />;
  }
}

LogOut.propTypes = {
  logOut: PropTypes.func.isRequired
};

export default connect(
  null,
  { logOut: apiService.logOut }
)(LogOut);
