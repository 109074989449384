import {
  PATIENT_LIST_REQUEST,
  PATIENT_LIST_SUCCESS,
  PATIENT_LIST_ERROR
} from '../actions/patients';

const initState = {
  data: {},
  isFetching: false,
  error: false
};

const patientsReducer = (state = initState, action) => {
  switch (action.type) {
    case PATIENT_LIST_REQUEST:
      return {
        ...state,
        error: false,
        isFetching: true
      };
    case PATIENT_LIST_SUCCESS:
      return {
        ...state,
        isFetching: false,
        data: action.payload
      };
    case PATIENT_LIST_ERROR:
      return {
        ...state,
        isFetching: false,
        error: action.error
      };
    default:
      return state;
  }
};

export default patientsReducer;
