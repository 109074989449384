import { combineReducers } from 'redux';
import {
  authReducer as auth,
  customerReducer as customer,
  userReducer as user,
  uploadReducer as upload,
  actionStackReducer as actionStack,
  fileReducer as file,
  CLEAR_STATE
} from '@brightsolutionsgmbh/client-core';
import patients from './patients';
import report from './report';
import orders from './orders';
import products from './products';
import haplotypes from './haplotypes';
import phenotypes from './phenotypes';

const appReducer = combineReducers({
  actionStack,
  auth,
  patients,
  customer,
  report,
  user,
  orders,
  upload,
  products,
  file,
  haplotypes,
  phenotypes
});

// wrapp combined reducers, this allows us to manipulate
// the global state when specific actions get fired
const rootReducer = (state, action) => {
  // catch logout action and clear global state
  if (action.type === CLEAR_STATE) {
    state = undefined;
  }

  return appReducer(state, action);
};

export default rootReducer;
