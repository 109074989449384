import React, { Component } from 'react';
import {Button, ButtonGroup} from 'reactstrap';
import "../../../../styles/content.scss";

class Paginator extends Component {
    constructor(props) {
      super(props);
  
      this.state = {
        
      };
    }
  
    render() {
      return (
        <ButtonGroup>
            <Button 
            color='outline-primary'
            onClick={() => this.props.goTo(0)}
            className="button-paginator">
            First
            </Button>
            <Button
            color='outline-primary' 
            onClick={() => this.props.goTo(this.props.currentPage-1)}
            className="button-paginator">
            Previous
            </Button>
            <Button 
            color='outline-primary' 
            onClick={() => this.props.goTo(this.props.currentPage)}
            className="button-paginator">
            {this.props.currentPage+1}
            </Button>
            <Button 
            color='outline-primary' 
            onClick={() => this.props.goTo(this.props.currentPage+1)}
            className="button-paginator">
            Next
            </Button>
            <Button 
            color='outline-primary' 
            onClick={() => this.props.goTo(Math.floor(this.props.count / this.props.elementsPerSide))}
            className="button-paginator">
            Last
            </Button>
        </ButtonGroup>
      )
    }
}

export default Paginator;