import qs from "qs";
import { rest } from "@brightsolutionsgmbh/client-core";

export const PATIENT_LIST_REQUEST = "@@patients/PATIENT_LIST_REQUEST";
export const PATIENT_LIST_SUCCESS = "@@patients/PATIENT_LIST_SUCCESS";
export const PATIENT_LIST_ERROR = "@@patients/PATIENT_LIST_ERROR";

export const getPatientList = ({
  page,
  patientId,
  startDate,
  endDate,
  itemsPerPage = 25,
}) => {
  const params = {
    page,
    patient_id: patientId,
    created: {
      min: startDate,
      max: endDate,
    },
    items_per_page: itemsPerPage,
  };

  return rest.request("GET", `/patient-list?${qs.stringify(params)}`, [
    PATIENT_LIST_REQUEST,
    PATIENT_LIST_SUCCESS,
    PATIENT_LIST_ERROR,
  ]);
};
