import React, { Component } from 'react';
import { UploadForm } from '@brightsolutionsgmbh/biologis-react-components';
import { connect } from 'react-redux';
import { PropTypes } from 'prop-types';
import { Table } from 'reactstrap';
import uniqBy from 'lodash/uniqBy';
import fileIcon from '../../images/file.svg';
import { apiService } from '../../services';

export class Upload extends Component {
  constructor(props) {
    super(props);

    this.state = {
      fileList: [],
      patients: []
    };
  }

  onFileUploadStarted = file => {
    this.setState({ fileList: [...this.state.fileList, file] });
  };

  onFileUploadCompleted = () => {
    let { fileList, patients } = this.state;
    const { patientIds } = this.props;
    fileList = [...fileList];
    patients = [...patients, ...patientIds];

    this.setState({ fileList, patients });
  };

  showStatusMessage = status => {
    switch (status) {
      case 0:
        return 'Loading...';
      case 1:
        return 'Upload completed';
      case 2:
        return 'Upload error!';
      default:
        return '';
    }
  };

  render() {
    const { uploadFile } = this.props;
    const { fileList, patients } = this.state;
    const patientIds =
      patients &&
      patients.length > 0 &&
      patients.map(order => order.property_value);
    const distinctPatientIds = uniqBy(patientIds);

    return (
      <div className="upload">
        <h1>Upload genotype data</h1>
        <UploadForm
          onFileUploadStarted={this.onFileUploadStarted}
          onFileUploadedCompleted={this.onFileUploadCompleted}
          upload={uploadFile}
        />
        {patientIds && (
          <p className="patients-id">
            Patients: {distinctPatientIds.join(', ')}
          </p>
        )}
        {fileList.length > 0 && (
          <Table className="file-list" striped>
            <tbody>
              {fileList.map((file, index) => (
                <tr key={index}>
                  <td>
                    <img src={fileIcon} alt="file-icon" />
                  </td>
                  <td>{file.name}</td>
                  <td>{this.showStatusMessage(file.status)}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        )}
      </div>
    );
  }
}

Upload.propTypes = {
  uploadFile: PropTypes.func.isRequired,
  patientIds: PropTypes.array,
  error: PropTypes.bool
};

const mapStateToProps = state => {
  return {
    patientIds: state.upload.data,
    error: state.upload.error
  };
};

export default connect(
  mapStateToProps,
  { uploadFile: apiService.uploadFile }
)(Upload);
