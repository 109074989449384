import React from "react";
import { Form, FormGroup, Label, Input, Button } from "reactstrap";
import { FormattedMessage, injectIntl, intlShape } from "react-intl";
import { PropTypes } from "prop-types";

const ItemsPerPageFilter = ({ options, onChange, msgId, value }) => {
  return (
    <FormGroup className="mb-2 mr-sm-2 mb-sm-0">
      <Label for="itemsPerPage" className="mr-sm-2">
        <FormattedMessage id={msgId} />
      </Label>
      <div>
        <Input
          type="select"
          name="itemsPerPage"
          value={value}
          onChange={onChange}
        >
          {options.map((item) => (
            <option>{item}</option>
          ))}
        </Input>
      </div>
    </FormGroup>
  );
};

ItemsPerPageFilter.propTypes = {
  options: PropTypes.array.isRequired,
  msgId: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.number,
};

export default ItemsPerPageFilter;
