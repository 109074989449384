import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { PropTypes } from "prop-types";
import { FormattedMessage, FormattedHTMLMessage } from "react-intl";
import {
  Paginator,
  Modal,
} from "@brightsolutionsgmbh/biologis-react-components";
import { Button } from "reactstrap";
import pickBy from "lodash/pickBy";
import identity from "lodash/identity";
import isNil from "lodash/isNil";
import dayjs from "dayjs";
import { getPatientList } from "../../actions/patients";
import { createReportOrder } from "../../actions/report";
import { getProducts } from "../../actions/products";
import GenerateReportForm from "./components/GenerateReportForm";
import GenerateReportFilter from "./components/GenerateReportFilter";
import { apiService } from "../../services";

export class Patients extends Component {
  constructor(props) {
    super(props);
    this.successModal = React.createRef();

    this.state = {
      filter: {},
      currentPage: 0,
      processedIds: [],
      tableData: [],
    };
  }

  componentDidMount() {
    this.onPageChange();
    this.getRequiredData();
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.location &&
      prevProps.location.search !== this.props.location.search
    ) {
      this.onPageChange();
    }
  }

  resetProcessedIdsState = () => {
    this.setState({ processedIds: [] });
  };

  handleFilterSubmit = ({ patientId, startDate, endDate, itemsPerPage }) => {
    // this.setState({ patientId, startDate, endDate, itemsPerPage });

    const filter = {
      patientId: patientId,
      startDate: startDate && dayjs(startDate).unix(),
      endDate:
        endDate &&
        dayjs(endDate)
          .add(86399, "second")
          .unix(),
      itemsPerPage: itemsPerPage,
    };

    this.setState(
      {
        filter,
      },
      () => {
        this.loadData(filter, 0);
      }
    );
  };

  loadData = (filter, page) => {
    const currentPage = isNil(page) ? this.state.currentPage : page;
    this.props
      .getPatientList(pickBy({ page: currentPage, ...filter }, identity))
      .then((action) => {
        this.setTableData(action.payload.results);
        this.setState({ currentPage: 0 });
      });
  };

  setTableData = (patients) => {
    const tableData = patients.map((item) => {
      item.lang = "";
      item.checked = false;

      return item;
    });
    this.setState({ tableData });
  };

  onPageChange = () => {
    const currentPage =
      new URLSearchParams(this.props.location.search).get("page") || 0;
    this.setState({ currentPage });
    this.props
      .getPatientList({ page: currentPage, ...this.state.filter })
      .then((action) => {
        this.setTableData(action.payload.results);
      });
  };

  getRequiredData = async () => {
    try {
      const { customerRelation, getCustomer, getProducts } = this.props;
      await getCustomer(`/as_customer/as_customer/${customerRelation.id}`);
      await getProducts();
      return true;
    } catch (e) {
      return e;
    }
  };

  getPatientsToProcess = (patients) =>
    patients.filter((patient) => patient.checked && patient.lang);

  submitForm = (e) => {
    const { customer, products, createReportOrder } = this.props;
    const { tableData } = this.state;

    const patientsToProcess = this.getPatientsToProcess(tableData);
    this.resetProcessedIdsState();

    if (products.length > 0 && patientsToProcess.length > 0) {
      patientsToProcess.forEach((patient, index) => {
        const body = {
          patient_id: patient.patient_id,
          order_id: patient.patient_id,
          langcode: patient.lang.value,
          orderer: customer.attributes.name,
          clinical_report: products[0].attributes.main_order.uuid,
        };

        createReportOrder(body).then((action) => {
          this.setState({
            processedIds: [...this.state.processedIds, action.payload.order_id],
          });

          if (
            this.successModal.current &&
            index === patientsToProcess.length - 1
          ) {
            this.successModal.current.onModalOpen();
          }
        });
      });
    }

    e.preventDefault();
  };

  checkItem = (value, index) => {
    const { tableData } = this.state;

    if (!isNil(index)) {
      tableData[index].checked = value;
    } else {
      tableData
        .filter((item) => item.lang && item.lang.value)
        .map((item) => {
          item.checked = value;
        });
    }

    this.setState({ tableData });
  };

  setLanguage = (option, index) => {
    const { tableData } = this.state;

    if (!isNil(index)) {
      tableData[index].lang = option;

      if (option.value === "") {
        tableData[index].checked = false;
      }
    } else {
      tableData.map((item) => {
        item.lang = option;

        if (option.value === "") {
          item.checked = false;
        }
      });
    }

    if (option.value === "") {
      tableData.map((item) => item.ch);
    }

    this.setState({ tableData });
  };

  render() {
    const { pager, createReportOrderIsFetching } = this.props;
    const { filter, currentPage, processedIds, tableData } = this.state;

    return (
      <div className="patients">
        <h1>
          <FormattedMessage id="patientList.header" />
        </h1>

        <GenerateReportFilter
          params={filter}
          handleSubmit={this.handleFilterSubmit}
        />
        {tableData.length > 0 && (
          <GenerateReportForm
            submit={this.submitForm}
            data={tableData}
            fetching={createReportOrderIsFetching}
            checkItem={this.checkItem}
            setLanguage={this.setLanguage}
          />
        )}

        {pager && pager.count ? (
          <Paginator
            totalItems={parseInt(pager.count)}
            currentPage={parseInt(currentPage)}
            startPageNum={0}
            onPageChange={this.onPageChange}
          />
        ) : (
          <p>
            <FormattedMessage id="patientList.noResults" />
          </p>
        )}
        <Modal
          ref={this.successModal}
          render={(toggle) => (
            <div>
              <FormattedHTMLMessage
                id="patientList.successModalMessage"
                values={{ ids: processedIds.join(", ") }}
              />
              <Button onClick={toggle} color="primary">
                <FormattedMessage id="patientList.successModalButton" />
              </Button>
            </div>
          )}
          withFooter={false}
          hideButton
        />
      </div>
    );
  }
}

Patients.propTypes = {
  location: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  getPatientList: PropTypes.func.isRequired,
  getCustomer: PropTypes.func.isRequired,
  getProducts: PropTypes.func.isRequired,
  createReportOrder: PropTypes.func.isRequired,
  customerRelation: PropTypes.object.isRequired,
  customer: PropTypes.object.isRequired,
  products: PropTypes.array,
  patients: PropTypes.array,
  pager: PropTypes.object,
  createReportOrderIsFetching: PropTypes.bool,
};

const mapStateToProps = (state) => {
  return {
    patients: state.patients.data.results,
    pager: state.patients.data.pager,
    customerRelation:
      state.user.data.relationships &&
      state.user.data.relationships.field_customer.data,
    customer: state.customer.data,
    products: state.products.data,
    createReportOrderIsFetching: state.report.isFetching,
  };
};

export default withRouter(
  connect(mapStateToProps, {
    getPatientList,
    getCustomer: apiService.getCustomer,
    getProducts,
    createReportOrder,
  })(Patients)
);
