import React, { Component } from "react";
import {
  Container,
  Row,
  Col,
  Navbar,
  Nav,
  NavItem,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { NavLink } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import { Logout } from "grommet-icons";
import { withRouter } from "react-router-dom";

export class Navigationbar extends Component {
  getLogo = () => {
    const { file } = this.props;
    let logo;

    if (file && file.attributes) {
      logo = `${process.env.REACT_APP_SERVER_ROOT}${file.attributes.uri.url}`;
    } else {
      logo = require(`../../../../images/${process.env.REACT_APP_SITE_LOGO}`);
    }

    return logo;
  };

  render() {
    return (
      <Row className="navigationbar">
        <Col>
          <Container>
            <Navbar expand="md">
              <img alt="" className="logo" src={this.getLogo()} />
              <Nav className="ml-auto" navbar>
                <NavItem>
                  <NavLink to="/upload" className="nav-link">
                    <FormattedMessage id="navbar.upload" />
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink to="/patients" className="nav-link">
                    <FormattedMessage id="navbar.patientList" />
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink to="/orders" className="nav-link">
                    <FormattedMessage id="navbar.orderList" />
                  </NavLink>
                </NavItem>
                {/* <UncontrolledDropdown nav inNavbar key='1'>
                  <DropdownToggle nav caret>
                    <FormattedMessage id="navbar.content" />
                  </DropdownToggle>
                  <DropdownMenu right>
                    <DropdownItem>
                      <NavItem>
                        <NavLink to="/dashboard_variants" className="nav-link">
                          <FormattedMessage id="navbar.dashboard" />
                        </NavLink>
                      </NavItem>
                    </DropdownItem>
                    <DropdownItem>
                      <NavItem>
                        <NavLink to="/variants" className="nav-link">
                          <FormattedMessage id="navbar.variants" />
                        </NavLink>
                      </NavItem>
                    </DropdownItem>
                    <DropdownItem>
                      <NavItem>
                        <NavLink to="/haplotypes" className="nav-link">
                          <FormattedMessage id="navbar.haplotypes" />
                        </NavLink>
                      </NavItem>
                    </DropdownItem>
                    <DropdownItem>
                      <NavItem>
                        <NavLink to="/phenotypes" className="nav-link">
                          <FormattedMessage id="navbar.phenotypes" />
                        </NavLink>
                      </NavItem>
                    </DropdownItem>
                  </DropdownMenu>
                </UncontrolledDropdown> */}
                <NavItem>
                  <NavLink to="/logout" className="nav-link">
                    <Logout className="stroke" />
                  </NavLink>
                </NavItem>
              </Nav>
            </Navbar>
          </Container>
        </Col>
      </Row>
    );
  }
}

Navigationbar.propTypes = {
  file: PropTypes.object,
};

const mapStateToProps = (state) => {
  return {
    file: state.file.data,
  };
};

export default withRouter(connect(mapStateToProps)(Navigationbar));
