import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { PropTypes } from "prop-types";
import { Paginator } from "@brightsolutionsgmbh/biologis-react-components";
import { downloadFileFromBlob } from "@brightsolutionsgmbh/client-core";
import { FormattedMessage, injectIntl, intlShape } from "react-intl";
import pickBy from "lodash/pickBy";
import identity from "lodash/identity";
import isNil from "lodash/isNil";
import { getReportOrders } from "../../actions/orders";
import { getReportOrder, setQuestions } from "../../actions/report";
import { apiService } from "../../services";
import FinalizeReportFilter from "./components/FinalizeReportFilter";
import FinalizeReportTable from "./components/FinalizeReportTable";

export class Orders extends Component {
  static loadingInterval = 5000;

  constructor(props) {
    super(props);

    this.state = {
      currentPage: 0,
      contentVariables: {},
      questionnaire: {},
      filter: {},
    };
  }

  componentDidMount() {
    this.onPageChange();

    this.dataPolling = setInterval(() => {
      this.loadData(this.state.filter);
    }, Orders.loadingInterval);
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.location &&
      prevProps.location.search !== this.props.location.search
    ) {
      this.onPageChange();
      clearInterval(this.dataPolling);
      this.dataPolling = setInterval(() => {
        this.loadData(this.state.filter);
      }, Orders.loadingInterval);
    }
  }

  componentWillUnmount() {
    if (this.dataPolling) {
      clearInterval(this.dataPolling);
    }
  }

  onPageChange = () => {
    const currentPage =
      new URLSearchParams(this.props.location.search).get("page") || 0;
    this.setState({ currentPage });
    this.loadData(this.state.filter, currentPage);
  };

  handleFilterSubmit = ({
    patientId,
    startDate,
    endDate,
    processingStatus,
    itemsPerPage,
  }) => {
    const filter = {
      patientId,
      startDate,
      endDate,
      processingStatus,
      itemsPerPage,
    };

    this.setState(
      {
        filter,
        currentPage: 0,
      },
      () => {
        this.loadData(filter, this.state.currentPage);
      }
    );
  };

  getContentVariables = async (order, toggleModal) => {
    try {
      let contentVariables = [];
      const response = await this.props.getReportOrder(
        order.id,
        "template_content_variables"
      );

      if (
        response.payload.data.attributes &&
        response.payload.data.attributes.template_content_variables
      ) {
        contentVariables = JSON.parse(
          response.payload.data.attributes.template_content_variables
        );
      }

      this.setState({ contentVariables });

      toggleModal();
    } catch (e) {
      console.log(e);
    }
  };

  getQuestions = async (order, toggleModal) => {
    try {
      let questionnaire = [];
      const response = await this.props.getReportOrder(
        order.id,
        "questionnaire"
      );

      if (
        response.payload.data.attributes &&
        response.payload.data.attributes.questionnaire
      ) {
        questionnaire = JSON.parse(
          response.payload.data.attributes.questionnaire
        );
      }

      this.setState({ questionnaire: questionnaire });

      toggleModal();
    } catch (e) {
      console.log(e);
    }
  };

  submitContentVariables = (values, actions, order, toggleModal) => {
    const { contentVariables, currentPage, filter } = this.state;
    const filledContentVariables = [];

    Object.keys(contentVariables).forEach((key) => {
      Object.keys(contentVariables[key].content_variables).forEach((k) => {
        const contentVariable = contentVariables[key].content_variables[k];
        if (values[contentVariable.name]) {
          const variable = {};
          variable.key = contentVariable.name;
          variable.value = values[contentVariable.name];
          variable.type = contentVariable.type;
          filledContentVariables.push(variable);
        }
      });
    });

    this.createPrintJobs({ order, filledContentVariables }).then(() => {
      actions.setSubmitting(false);
      this.loadData(filter, currentPage);
      toggleModal();
    });
  };

  submitQuestions = (values, order, toggleModal, process = false) => {
    this.props.setQuestions(values, order.id, process);
    toggleModal();
  };

  createPrintJobs = ({ order, filledContentVariables }) => {
    const { createPrintJob } = this.props;
    const printJobs = [];

    ((order.attributes || {}).print_job_sub_order_data || []).forEach(
      (subOrder) => {
        let printJob = createPrintJob({
          id: subOrder.target.target_id,
          targetType: subOrder.target.target_type,
          printType: subOrder.field_print_type,
          contentVariables: filledContentVariables,
          langCode: order.attributes.langcode,
        });
        printJobs.push(printJob);
      }
    );

    return Promise.all(printJobs);
  };

  downloadFile = (uri) => {
    const { downloadFile } = this.props;
    const splittedUri = uri.split("/");
    const filename = splittedUri[splittedUri.length - 1];

    downloadFile(uri).then((action) => {
      action.payload
        .blob()
        .then((blob) => downloadFileFromBlob(blob, filename));
    });
  };

  loadData = (filter, page) => {
    const currentPage = isNil(page) ? this.state.currentPage : page;
    this.props.getReportOrders(
      pickBy({ page: currentPage, ...filter }, identity)
    );
  };

  render() {
    const { orders } = this.props;
    const { contentVariables, questionnaire, currentPage } = this.state;

    return (
      <div className="orders">
        <h1>
          <FormattedMessage id="orderList.header" />
        </h1>

        <FinalizeReportFilter handleSubmit={this.handleFilterSubmit} />

        {orders && orders.data && orders.data.length > 0 && (
          <FinalizeReportTable
            orders={orders}
            questionnaire={questionnaire}
            contentVariables={contentVariables}
            getQuestions={this.getQuestions}
            getContentVariables={this.getContentVariables}
            submitContentVariables={this.submitContentVariables}
            submitQuestions={this.submitQuestions}
            downloadFile={this.downloadFile}
          />
        )}
        {orders.meta && orders.meta.count && orders.meta.count !== "0" ? (
          <Paginator
            totalItems={parseInt(orders.meta.count)}
            currentPage={parseInt(currentPage)}
            startPageNum={0}
            pageSize={10}
            onPageChange={this.onPageChange}
          />
        ) : (
          <p>
            <FormattedMessage id="orderList.noResults" />
          </p>
        )}
      </div>
    );
  }
}

Orders.propTypes = {
  location: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  getReportOrders: PropTypes.func.isRequired,
  getReportOrder: PropTypes.func.isRequired,
  createPrintJob: PropTypes.func.isRequired,
  downloadFile: PropTypes.func.isRequired,
  setQuestions: PropTypes.func.isRequired,
  intl: intlShape.isRequired,
  orders: PropTypes.object,
  tokens: PropTypes.object,
};

const mapStateToProps = (state) => {
  return {
    orders: state.orders.data,
    tokens: state.auth.tokens,
  };
};

export default withRouter(
  connect(mapStateToProps, {
    getReportOrders,
    getReportOrder,
    setQuestions,
    createPrintJob: apiService.createPrintJob,
    downloadFile: apiService.downloadFile,
  })(injectIntl(Orders))
);
