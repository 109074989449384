import React, { Component } from 'react';
import { Container, Col, Row, Card, CardHeader, CardBody, Table, Button } from 'reactstrap';
import "../../../../../../styles/content.scss";

export class HaplotypeTable extends Component {
    constructor(props) {
      super(props);
  
      this.state = {
        currentPage: 0,
      };
    }

    render () {
      const { haplotypes } = this.props;
      return (
        <Container>
          <Row className="mt-5">
            <Col>
              <Card>
                <CardHeader>
                  <Row>
                    <Col sm="2">
                      All haplotypes
                    </Col>
                    <Col sm={{ size: 1, offset: 9 }}>
                      <Button
                        color="primary"
                        size="sm"
                        className="button-add">
                        +
                      </Button>
                    </Col>
                  </Row>
                </CardHeader>
                <CardBody>
                  <Table hover>
                    <thead>
                      <tr>
                        <th>Gene</th>
                        <th>Variant result</th>
                        <th>haplotype</th>
                        <th>associated phenotype</th>
                        <th>origin</th>
                        <th>last author</th>
                        <th>last update</th>
                        <th>status</th>
                      </tr>
                    </thead>
                    <tbody>
                      { haplotypes.map((htypes, index) => {
                        return (
                          <tr key={htypes.id}>
                            <td>{htypes.attributes.name}</td>
                            <td>{htypes.attributes.field_kmm_haplotype_desc}</td>
                            <td>{"active"}</td>
                            <td>{htypes.attributes.created}</td>
                            <td>{"???"}</td>
                            <td>{"???"}</td>
                            <td>{"???"}</td>
                            <td>{"???"}</td>
                          </tr>
                        )
                      })}
                    </tbody>
                  </Table>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      )
    }
}

export default HaplotypeTable;