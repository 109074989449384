import { jsonapi } from '@brightsolutionsgmbh/client-core';
import qs from 'qs';

export const HAPLOTYPES_REQUEST = 'HAPLOTYPES_REQUEST';
export const HAPLOTYPES_SUCCESS = 'HAPLOTYPES_SUCCESS';
export const HAPLOTYPES_ERROR = 'HAPLOTYPES_ERROR';

export const getHaplotypes = ( {
  page,
  haplotypeId,
  gene
} ) => {

  const params = {

    filter: {
      haplotypeId: {
        condition: {

        }
      },
      gene: {
        condition: {

        }
      }
    }, 

    page: {
      limit: 5,
      offset: page ? 5 * page : 0
    },
  }

  if (haplotypeId) {
    params.filter.haplotypeId.condition = {
      path: 'name',
      operator: 'CONTAINS',
      value: haplotypeId
    };
  }

  if (gene) {
    params.filter.gene.condition = {
      path: 'name',
      operator: 'CONTAINS',
      value: gene
    };
  }

  return jsonapi.request(
    'GET',
    `/as_metadata/ep_haplotype/?${qs.stringify(params)}`,
    [HAPLOTYPES_REQUEST, HAPLOTYPES_SUCCESS, HAPLOTYPES_ERROR]
  );
};