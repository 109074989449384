import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { getPhenotypes } from '../../../../actions/phenotypes';
import { Container, Row } from 'reactstrap';
import PhenotypeFilter from './components/PhenotypeFilter';
import PhenotypeTable from './components/PhenotypeTable';
import Paginator from '../Paginator'
import "../../../../styles/content.scss";

export class Phenotypes extends Component {
    static loadingInterval = 5000;
  
    constructor(props) {
      super(props);
  
      this.state = {
        currentPage: 0,
        loading: null,
        filter: {},
        count: null,
        phenotypes: []
      };
    }

    componentDidMount () {
      this.setState({ loading: true })
      this.onPageChange();
    }

    setPhenotypes = phenotypes => {
      this.setState({ phenotypes });
    };

    handleFilterSubmit = ({
        phenotypeId
    }) => {

      const filter = {
        phenotypeId: phenotypeId,
      };
      this.setState({ currentPage: 0, filter: filter }, () => 
        this.loadData(filter))
    };
  
    onPageChange = () => {
        this.loadData(this.state.filter);
      }

    loadData = (filter) => {
      this.setState({ loading: true })
      this.props
        .getPhenotypes({ page: this.state.currentPage, ...filter })
        .then(action => {
          this.setPhenotypes(action.payload.data);
          this.setState({ count: action.payload.meta.count })
      
      })
      this.setState({ loading: false })
    };

    handleGoToPage (page) {
      if (page >= 0 && page <= Math.floor(this.state.count/5)) {
        this.setState({ currentPage: page }, () => {
          this.onPageChange()}) 
      }
    }

    render () {
      return (
        <Container>
            <Row className="m-5">
                <PhenotypeFilter
                    handleSubmit={this.handleFilterSubmit}/>
            </Row>
            <Row>
                <PhenotypeTable
                    data={this.state.phenotypes}
                    goTo={this.handleGoToPage}
                    count={this.state.count}/>
            </Row>
            <Row className="ml-5 m-3">
                <Paginator
                    goTo={(page) => this.handleGoToPage(page)}
                    count={this.state.count}
                    currentPage={this.state.currentPage}
                    elementsPerSide={5}/>
            </Row>
        </Container>
        )
    }
}

const mapStateToProps = state => {
  return {
    
  };
};

export default withRouter(
  connect(
    mapStateToProps,
    {
      getPhenotypes,
    }
  )(Phenotypes)
);