import { jsonapi } from '@brightsolutionsgmbh/client-core';
import qs from 'qs';

export const PHENOTYPES_REQUEST = 'PHENOTYPES_REQUEST';
export const PHENOTYPES_SUCCESS = 'PHENOTYPES_SUCCESS';
export const PHENOTYPES_ERROR = 'PHENOTYPES_ERROR';

export const getPhenotypes = ( {
  page,
  phenotypeId
} ) => {

  const params = {

    filter: {
      phenotypeId: {
        condition: {

        }
      }
    }, 

    page: {
      limit: 5,
      offset: page ? 5 * page : 0
    },
  }

  if (phenotypeId) {
    params.filter.phenotypeId.condition = {
      path: 'name',
      operator: 'CONTAINS',
      value: phenotypeId
    };
  }

  return jsonapi.request('GET', `/as_metadata/ep_assoc_pheno/?${qs.stringify(params)}`, [
    PHENOTYPES_REQUEST,
    PHENOTYPES_SUCCESS,
    PHENOTYPES_ERROR
  ]);
};