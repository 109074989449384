import React from 'react';
import { Button } from 'reactstrap';
import { PropTypes } from 'prop-types';

const DownloadButton = ({ item, downloadFile }) => (
  <Button
    color="link"
    onClick={() =>
      downloadFile(`${process.env.REACT_APP_SERVER_ROOT}${item.link}`)
    }
  >
    {item.type}
  </Button>
);

DownloadButton.propTypes = {
  item: PropTypes.object,
  downloadFile: PropTypes.func.isRequired
};

export default DownloadButton;
