import { ApiService, StateService } from '@brightsolutionsgmbh/client-core';

const config = {
  serverRoot: process.env.REACT_APP_SERVER_ROOT,
  jsonApi: process.env.REACT_APP_JSON_API,
  jsonRpc: process.env.REACT_APP_JSON_RPC,
  rest: process.env.REACT_APP_REST,
  clientId: process.env.REACT_APP_CLIENT_ID,
  clientSecret: process.env.REACT_APP_CLIENT_SECRET
};

ApiService.init(config);
StateService.init();

export const apiService = ApiService;
export const stateService = StateService;
