import React from 'react';
import ReactDOM from 'react-dom';
import configureStore from './configureStore';
import Root from './Root';

window.__MUI_USE_NEXT_TYPOGRAPHY_VARIANTS__ = true;

const store = configureStore();

ReactDOM.render(<Root store={store} />, document.getElementById('root'));
