import React, { Component } from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import PropTypes from "prop-types";
import { Col, Row, Container } from "reactstrap";
import { LogInForm } from "@brightsolutionsgmbh/biologis-react-components";
import { FormattedMessage } from "react-intl";
import logo from "../../images/bio_Logo_GIMS-4c.jpg";
import { apiService } from "../../services";
import { toast } from "react-toastify";

export class LogIn extends Component {
  constructor(props) {
    super(props);
  }

  handleLogin = async (username, password, queryParams) => {
    try {
      const userAction = await this.props.logIn(
        username,
        password,
        queryParams
      );
      const { user } = this.props;

      if (userAction.name === "Error") {
        toast.error("Invalid credentials");
      }

      if (userAction.ok !== false) {
        const customerAction = await this.props.getCustomer(
          `/as_customer/as_customer/${user.relationships.field_customer.data.id}`
        );

        if (
          !customerAction.error &&
          (
            (
              (((customerAction.payload || {}).data || {}).relationships || {})
                .drm_logo || {}
            ).data || {}
          ).id
        ) {
          await this.props.getFile(
            customerAction.payload.data.relationships.drm_logo.data.id
          );
        }
      }
    } catch (e) {
      console.error(e);
    }
  };

  render() {
    const { isLoggedIn, user } = this.props;

    if (isLoggedIn && user.id) {
      return <Redirect to="/upload" />;
    }

    return (
      <div className="login">
        <Container>
          <Row>
            <Col xs="12" md={{ size: 6, offset: 3 }}>
              <div className="form-wrapper">
                <img
                  alt={process.env.REACT_APP_SITE_NAME}
                  className="logo my-2"
                  src={logo}
                />
                <h1>
                  <FormattedMessage id="login.welcome" />
                </h1>
                <LogInForm
                  logIn={this.handleLogin}
                  queryParams={{ language_content_entity: "en" }}
                />
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

LogIn.propTypes = {
  logIn: PropTypes.func.isRequired,
  isLoggedIn: PropTypes.bool.isRequired,
  error: PropTypes.string,
  getCustomer: PropTypes.func.isRequired,
  getFile: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  return {
    isLoggedIn: state.auth.isLoggedIn,
    error: state.auth.error,
    user: state.user.data,
    networkError: state.networkError,
  };
};

export default connect(mapStateToProps, {
  logIn: apiService.logIn,
  getCustomer: apiService.getCustomer,
  getFile: apiService.getFile,
})(LogIn);
