import React, { Component } from 'react';
import { Container, Col, Row, Card, CardHeader, CardBody, CardFooter, Table, Nav, NavItem, NavLink, TabContent, TabPane, Button} from 'reactstrap';
export class Dashboard extends Component {

    constructor(props) {
        super(props);

        this.state = {

        };
    }

    render() {
        const { haplotypes, phenotypes, toggleTab, activeTab, toggleEditor, handleClickOnRow } = this.props;
        return (
            <Container>
                <Row className="mt-5">
                    <Col>
                        <Card>
                            <CardHeader>
                                Dashboard
                            </CardHeader>
                            <CardBody>
                                <Nav tabs>
                                    <NavItem>
                                        <NavLink
                                            onClick={() => { toggleTab('haplotypes'); }}
                                            active={activeTab === 'haplotypes'}>
                                            Haplotypes (0)
                                    </NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink
                                            onClick={() => { toggleTab('phenotypes'); }}
                                            active={activeTab === 'phenotypes'}>
                                            Phenotypes (0)
                                    </NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink
                                            onClick={() => { toggleTab('activeIngredients'); }}
                                            active={activeTab === 'activeIngredients'}>
                                            Active ingredients (0)
                                    </NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink
                                            onClick={() => { toggleTab('recommendations'); }}
                                            active={activeTab === 'recommendations'}>
                                            Recommendations (0)
                                    </NavLink>
                                    </NavItem>
                                </Nav>
                                <TabContent activeTab={activeTab}>
                                    <TabPane tabId='haplotypes'>
                                        <Table hover>
                                            <thead>
                                                <tr>
                                                    <th>Gene</th>
                                                    <th>Haplotype</th>
                                                    <th>Status</th>
                                                    <th>Date and origin of import</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {haplotypes.map((htypes, index) => {
                                                    return (
                                                        <tr key={htypes.id} id={htypes.id} onClick={(e) => handleClickOnRow(e, index)}>
                                                            <td id='gene'>{htypes.attributes.name}</td>
                                                            <td id='haplotype'>{htypes.attributes.field_kmm_haplotype_desc}</td>
                                                            <td id='status'>{"active"}</td>
                                                            <td id='import'>{htypes.attributes.created}</td>
                                                        </tr>
                                                    )
                                                })}
                                            </tbody>
                                        </Table>
                                    </TabPane>
                                    <TabPane tabId='phenotypes'>
                                        <Table hover>
                                            <thead>
                                                <tr>
                                                    <th>Gene</th>
                                                    <th>Haplotype</th>
                                                    <th>Status</th>
                                                    <th>Date and origin of import</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {phenotypes.map((ptypes, index) => {
                                                    return (
                                                        <tr key={ptypes.id} id={ptypes.id} onClick={(e) => handleClickOnRow(e, index)}>
                                                            <td id='gene'>{ptypes.id}</td>
                                                            <td id='haplotype'></td>
                                                            <td id='status'></td>
                                                            <td id='import'></td>
                                                        </tr>
                                                    )
                                                })}
                                            </tbody>
                                        </Table>
                                    </TabPane>
                                    <TabPane tabId='activeIngredients'>
                                        <Table hover>
                                            <thead>
                                                <tr>
                                                    <th>Gene</th>
                                                    <th>Haplotype</th>
                                                    <th>Status</th>
                                                    <th>Date and origin of import</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {haplotypes.map((htypes, index) => {
                                                    return (
                                                        <tr key={htypes.id} id={htypes.id} onClick={(e) => handleClickOnRow(e, index)}>
                                                            <td id='gene'>{htypes.attributes.name}</td>
                                                            <td id='haplotype'>{htypes.attributes.field_kmm_haplotype_desc}</td>
                                                            <td id='status'>{"active"}</td>
                                                            <td id='import'>{htypes.attributes.created}</td>
                                                        </tr>
                                                    )
                                                })}
                                            </tbody>
                                        </Table>
                                    </TabPane>
                                    <TabPane tabId='recommendations'>
                                        <Table hover>
                                            <thead>
                                                <tr>
                                                    <th>Gene</th>
                                                    <th>Haplotype</th>
                                                    <th>Status</th>
                                                    <th>Date and origin of import</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {haplotypes.map((htypes, index) => {
                                                    return (
                                                        <tr key={htypes.id} id={htypes.id} onClick={(e) => handleClickOnRow(e, index)}>
                                                            <td id='gene'>{htypes.attributes.name}</td>
                                                            <td id='haplotype'>{htypes.attributes.field_kmm_haplotype_desc}</td>
                                                            <td id='status'>{'active'}</td>
                                                            <td id='import'>{htypes.attributes.created}</td>
                                                        </tr>
                                                    )
                                                })}
                                            </tbody>
                                        </Table>
                                    </TabPane>
                                </TabContent>
                            </CardBody>
                            <CardFooter>
                                <Button color='primary' size='sm' onClick={() => toggleEditor('newVariant')}>
                                    Manual variant entry
                                </Button>
                            </CardFooter>
                        </Card>
                    </Col>
                </Row>
            </Container>
        )
    }
}

export default Dashboard