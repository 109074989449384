import React, { Component } from 'react';
import { Button, Table } from 'reactstrap';
import { PropTypes } from 'prop-types';
import { FormattedMessage, injectIntl, intlShape } from 'react-intl';
import { Spinner } from 'reactstrap';
import Select from 'react-select';
import dayjs from 'dayjs';

class GenerateReportForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedAll: false,
      selectedLang: null
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.data !== this.props.data) {
      this.setState({ selectedAll: false, selectedLang: '' });
    }
  }

  render() {
    const { data, submit, setLanguage, checkItem, intl, fetching } = this.props;
    const langOptions = [
      {
        value: 'de',
        label: intl.formatMessage({
          id: 'patientList.optionGerman'
        })
      },
      {
        value: 'en',
        label: intl.formatMessage({
          id: 'patientList.optionEnglish'
        })
      }
    ];

    return (
      data.length > 0 && (
        <form onSubmit={submit}>
          <Table striped>
            <thead>
              <tr>
                <th>
                  <FormattedMessage id="patientList.patientId" />
                </th>
                <th>
                  <FormattedMessage id="patientList.requestDate" />
                </th>
                <th>
                  <FormattedMessage id="patientList.reportLanguage" />
                  <Select
                    placeholder={intl.formatMessage({
                      id: 'patientList.optionSelectForAll'
                    })}
                    options={[
                      {
                        value: '',
                        label: intl.formatMessage({
                          id: 'patientList.optionSelectForAll'
                        })
                      },
                      ...langOptions
                    ]}
                    onChange={option => {
                      setLanguage(option);
                      this.setState({ selectedLang: option });
                    }}
                    value={this.state.selectedLang}
                  />
                </th>
                <th className="text-center">
                  <FormattedMessage id="patientList.generateReport" />
                  <div>
                    <input
                      value={this.state.selectedAll}
                      checked={this.state.selectedAll}
                      type="checkbox"
                      name="checked"
                      onChange={e => {
                        this.setState({ selectedAll: e.target.checked });
                        checkItem(e.target.checked);
                      }}
                    />
                  </div>
                </th>
              </tr>
            </thead>
            <tbody>
              {data.map((patient, index) => (
                <tr key={index}>
                  <td className="grey">{patient.patient_id}</td>
                  <td>
                    {dayjs
                      .unix(patient.created)
                      .format('ddd, DD/MM/YYYY - HH:mm')}
                  </td>
                  <td>
                    <Select
                      value={patient.lang}
                      placeholder={intl.formatMessage({
                        id: 'patientList.optionSelect'
                      })}
                      options={[
                        {
                          value: '',
                          label: intl.formatMessage({
                            id: 'patientList.optionSelect'
                          })
                        },
                        ...langOptions
                      ]}
                      onChange={option => setLanguage(option, index)}
                    />
                  </td>
                  <td className="text-center">
                    <input
                      type="checkbox"
                      checked={patient.checked}
                      value={patient.checked}
                      disabled={!patient.lang || patient.lang.value === ''}
                      onChange={e => checkItem(e.target.checked, index)}
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
          {fetching && <Spinner className="float-right" color="primary" />}
          <Button
            color="primary"
            className={`float-right ${fetching ? 'is-fetching' : ''}`}
            type="submit"
          >
            <FormattedMessage id="patientList.proceedButton" />
          </Button>
        </form>
      )
    );
  }
}

GenerateReportForm.propTypes = {
  submit: PropTypes.func.isRequired,
  setLanguage: PropTypes.func.isRequired,
  checkItem: PropTypes.func.isRequired,
  data: PropTypes.array,
  intl: intlShape.isRequired,
  fetching: PropTypes.bool
};

export default injectIntl(GenerateReportForm);
