import React, { Component } from 'react';
import {
  Container,
  Col,
  Row,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Table,
  Button,
  Modal,
  ModalBody,
  InputGroup,
  Input,
  Label } from 'reactstrap';
import "../../../../../../styles/content.scss";

export class PhenotypeTable extends Component {
    static loadingInterval = 5000;
  
    constructor(props) {
      super(props);
  
      this.state = {
        addPhenotype: false,
        newPhenotype: '',
      };

      this.toggleAddPhenotype = this.toggleAddPhenotype.bind(this)
      this.handleChange = this.handleChange.bind(this)
    }

    toggleAddPhenotype () {
      this.setState({addPhenotype: !this.state.addPhenotype})
    }

    handleChange (e) {
      this.setState({newPhenotype: e.target.value})
    }

    render () {
      return (
        <Container>
            <Row>
              <Col>
                <Card>
                  <CardHeader>
                    <Container>
                      <Row>
                        <Col sm="2">
                          All phenotypes
                        </Col>
                        <Col sm={{ size: 1, offset: 9 }}>
                          <Button
                            color="primary"
                            size="sm"
                            className="button-add"
                            onClick={this.toggleAddPhenotype}>
                            +
                          </Button>
                        </Col>
                      </Row>
                      </Container>
                  </CardHeader>
                  <CardBody>
                    <Table striped>
                      <thead>
                        <tr>
                          <th>phenotype</th>
                          <th>origin</th>
                          <th>last author</th>
                          <th>last update</th>
                          <th>status</th>
                        </tr>
                      </thead>
                      <tbody>
                        { this.props.data.map((ptypes) => {
                          return (
                            <tr key={ptypes.id}>
                              <td>{ptypes.id}</td>
                              <td>{ptypes.attributes.created}</td>
                              <td>User</td>
                              <td>{ptypes.attributes.changed}</td>
                              <td>{ptypes.attributes.status ? "active": "unactive"}</td>
                            </tr>
                          )
                        })}
                      </tbody>
                    </Table>
                  </CardBody>
                </Card>
              </Col>
          </Row>

          <Modal isOpen={this.state.addPhenotype} size="lg">
            <ModalBody>
              <Card>
                <CardHeader>
                  Add new Phenotype
                </CardHeader>
                <CardBody>
                  <Row className="mt-2">
                    <InputGroup>
                      <Col sm={3}>
                        <Label>
                          Associated Gene:
                        </Label>
                      </Col>
                      <Col sm={9}>
                        <Input
                          type="select"
                          size="sm">
                          <option>Choose associated Gene</option>
                          <option>A</option>
                          <option>B</option>
                        </Input>                    
                      </Col>
                    </InputGroup>
                  </Row>
                  <Row className="mt-2">
                    <InputGroup>
                      <Col sm={3}>
                        <Label>
                          New phenotype:
                        </Label>
                      </Col>
                      <Col sm={9}>
                        <Input
                          id="newPhenotype"
                          type="text"
                          value={this.state.newPhenotype}
                          placeholder="Enter new phenotype"
                          size="sm"
                          onChange={this.handleChange}/>                   
                      </Col>
                    </InputGroup>
                  </Row>
                </CardBody>
                <CardFooter>
                  <Row>
                    <Col sm={{size: 2, offset: 8}}>
                      <Button
                        color="outline-secondary"
                        className="button-paginator"
                        size="sm">
                        Add
                      </Button>
                    </Col>
                    <Col sm={2}>
                      <Button
                        color="outline-secondary"
                        className="button-paginator"
                        size="sm"
                        onClick={this.toggleAddPhenotype}>
                        Close
                      </Button>
                    </Col>
                  </Row>
                </CardFooter>
              </Card>
            </ModalBody>
          </Modal>
        </Container>
        )
    }
}

export default PhenotypeTable;