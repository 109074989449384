import { jsonapi } from "@brightsolutionsgmbh/client-core";
import qs from "qs";
import dayjs from "dayjs";

export const ORDER_LIST_REQUEST = "@@orders/ORDER_LIST_REQUEST";
export const ORDER_LIST_SUCCESS = "@@orders/ORDER_LIST_SUCCESS";
export const ORDER_LIST_ERROR = "@@orders/ORDER_LIST_ERROR";

export const getReportOrders = ({
  page,
  patientId,
  startDate,
  endDate,
  processingStatus,
  itemsPerPage = 10,
}) => {
  const params = {
    sort: "-request_timestamp",
    filter: {
      parent: {
        condition: {
          operator: "IS NULL",
          path: "parent.id",
        },
      },
      "target.meta.target_type": "node",
      processing_status: processingStatus,
    },
    include: "target",
    page: {
      limit: itemsPerPage,
      offset: page ? itemsPerPage * page : 0,
    },
    fields: {
      "as_report_order--as_report_order":
        "combined_processing_status,processing_status,print_job_sub_order_data,print_job_download_urls,print_job_combined_processing_status,template_content_variables,langcode,patient_id,clinical_report,request_timestamp,print_job_download_urls,print_job_status,order_processing_status,target",
      "node--clinical_report": "title",
    },
  };

  if (patientId) {
    params.filter.patient_id = {
      operator: "CONTAINS",
      value: patientId,
    };
  }

  if (startDate) {
    params.filter.startDate = {
      condition: {
        operator: ">=",
        path: "request_timestamp",
        value: dayjs(startDate).unix(),
      },
    };
  }

  if (endDate) {
    params.filter.endDate = {
      condition: {
        operator: "<=",
        path: "request_timestamp",
        value: dayjs(endDate).unix(),
      },
    };
  }

  return jsonapi.request(
    "GET",
    `/as_report_order/as_report_order?${qs.stringify(params, {
      format: "RFC1738",
    })}`,
    [ORDER_LIST_REQUEST, ORDER_LIST_SUCCESS, ORDER_LIST_ERROR]
  );
};
