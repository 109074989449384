import React, { Component } from 'react';
import { Container, Col, Row, Card, CardHeader, CardBody, CardFooter, Table, Button, Label } from 'reactstrap';
import { Formik, Form, Field, FieldArray, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import "../../../../styles/content.scss";

export class HaplotypeEditor extends Component {
  
    constructor(props) {
      super(props);
  
      this.state = {
        variants: [],
        assocPhenotypes: [],
      };
    }

    render () {
      const { haplotypes, editing, closeEditing } = this.props;

      const validationSchema = Yup.object().shape({
        haplotypeName: Yup.string()
          .required('Required!')
      })
      return (
        <Formik
          initialValues = {{haplotypeName: haplotypes[editing].attributes.name, haplotypeStatus: '1', assocPhenotypes: this.state.assocPhenotypes}}
          validationSchema = {validationSchema}
          render= {({ values }) => (
            <Container>
              <Card className='mt-5'>
                <CardHeader>
                  <Row>
                    <Col xs='4'>
                      Info:
                    </Col>
                    <Col>
                      {haplotypes[editing].attributes.name}
                    </Col>
                    <Col>
                      ???
                    </Col>
                    <Col>
                      ???
                    </Col>
                  </Row>
                </CardHeader>
                <CardBody>
                  <Row>
                    <Col xs='2'>
                      Variants:
                    </Col>
                    <Col xs='10'>
                      {this.state.variants && this.state.variants.length > 0 ?
                      (
                        <Table>
                          <thead>
                            <tr>
                              <th>Gene</th>
                              <th>cDNA</th>
                              <th>protein change</th>
                              <th>zygosity</th>
                              <th>rsID</th>
                            </tr>
                          </thead>
                          <tbody>
                              {this.state.variants.map((variant, index) => (
                                <tr key={index}>
                                  <td>A</td>
                                  <td>B</td>
                                  <td>C</td>
                                  <td>D</td>
                                  <td>E</td>
                                </tr>
                              ))}
                          </tbody>
                        </Table>
                      ) : (
                        <Row>
                          <Col>
                            No variants found!
                          </Col>
                        </Row>
                      )}
                    </Col>
                  </Row>
                  <hr/>
                  <Row>
                    <Col xs='2'>
                      <Label>Name:</Label>
                    </Col>
                    <Col xs='10'>
                        <Row>
                          <Col xs='7'>
                            <Form>
                              <Field name='haplotypeName' className='formik-field'/>
                              <ErrorMessage name='haplotypeName' className='invalid-feedback'/>
                            </Form>
                          </Col>
                          <Col sm={{size:2, offset:3}}>
                            <Form>
                              <Field name='haplotypeStatus' component='select' className='formik-select'>
                                <option value='1'>new</option>
                                <option value='2'>review</option>
                              </Field>
                            </Form>
                          </Col>
                        </Row>
                    </Col>
                  </Row>
                  <hr/>
                  <Row>
                    <Col xs='2'>
                      <Label>Assoc. phenotype:</Label>
                    </Col>
                    <Col xs='10'>
                      <FieldArray
                        name='assocPhenotypes'
                        render={arrayHelpers => (
                          <div>
                            {values.assocPhenotypes && values.assocPhenotypes.length > 0 ?
                              <div>
                              {values.assocPhenotypes.map((phenotype, index) => (
                                <Row key={index} className={index !== 0 ? 'mt-2' : ''}>
                                  <Col xs='7'>
                                    <Field name={`assocPhenotypes.${index}`} component='select' className='formik-select'>
                                      <option>Phenotype A</option>
                                      <option>Phenotype B</option>
                                    </Field>
                                  </Col>
                                  <Col xs='1'>
                                    <Button size='sm' color='light' onClick={() => console.log('Infos')}>show</Button>
                                  </Col>
                                  <Col xs='1'>
                                    <Button size='sm' color='light' onClick={() => arrayHelpers.remove(index)}>delete</Button>
                                  </Col>
                                  <Col sm={{size:2, offset:1}}>
                                    <Field name={`assocPhenotypes.${index}.status`} component='select' className='formik-select'>
                                      <option>new</option>
                                      <option>review</option>
                                    </Field>
                                  </Col>
                                </Row>
                              ))}
                              <Row>
                                <Col xs='7'>
                                  <Button size='sm' color='outline-primary' onClick={() => arrayHelpers.push('')} className='button-large'>Add phenotype</Button>
                                </Col>
                              </Row>
                              </div> : (
                                <Button size='sm' color='light' onClick={() => arrayHelpers.push('')} className='button-large'>
                                  Add phenotype
                                </Button>
                              )}
                          </div>
                        )}>
                      </FieldArray>
                    </Col>
                  </Row>
                </CardBody>
                <CardFooter>
                  <Col md={{offset: 10}}>
                    <Button size='sm' className='mr-2' color='primary'>
                      Save
                    </Button>
                    <Button size='sm' color='primary' onClick={closeEditing}>
                      Close
                    </Button>
                  </Col>
                </CardFooter>
              </Card>
            </Container>
          )}/>
        )
    }
}

export default HaplotypeEditor