import React, { Component } from 'react';
import { Container, Col, Row, Card, CardHeader, CardBody, CardFooter, Table, Button } from 'reactstrap';
import "../../../../../../styles/content.scss";

export class VariantsTable extends Component {
    static loadingInterval = 5000;
  
    constructor(props) {
      super(props);
  
      this.state = {
        currentPage: 0,

      };
    }

    render () {
      return (
        <Container>
            <Row>
              <Col>
                <Card>
                  <CardHeader>
                    <Row>
                      <Col sm="2">
                        All variants
                      </Col>
                      <Col sm={{ size: 1, offset: 9 }}>
                        <Button
                          color="primary"
                          size="sm"
                          className="button-add">
                          +
                        </Button>
                      </Col>
                    </Row>
                  </CardHeader>
                  <CardBody>
                    <Table striped>
                      <thead>
                        <tr>
                          <th>Gene</th>
                          <th>Transcript</th>
                          <th>Variant Name</th>
                          <th>c-Position</th>
                          <th>p-Position</th>
                          <th>Added when/by</th>
                        </tr>
                      </thead>
                      <tbody>
                        { this.props.data.map((htypes) => {
                          return (
                            <tr key={htypes.id}>
                              <td>{"???"}</td>
                              <td>{"???"}</td>
                              <td>{"???"}</td>
                              <td>{"???"}</td>
                              <td>{"???"}</td>
                              <td>{"???"}</td>
                            </tr>
                          )
                        })}
                      </tbody>
                    </Table>
                  </CardBody>
                  <CardFooter>

                  </CardFooter>
                </Card>
              </Col>
          </Row>
        </Container>
        )
    }
}

export default VariantsTable;