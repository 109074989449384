import {
    HAPLOTYPES_REQUEST,
    HAPLOTYPES_SUCCESS,
    HAPLOTYPES_ERROR
  } from '../actions/haplotypes';
  
  const initState = {
    data: [],
    isFetching: false,
    error: false
  };
  
  const haplotypesReducer = (state = initState, action) => {
    switch (action.type) {
      case HAPLOTYPES_REQUEST:
        return {
          ...state,
          error: false,
          isFetching: true
        };
      case HAPLOTYPES_SUCCESS:
        return {
          ...state,
          isFetching: false,
          data: action.payload.data
        };
      case HAPLOTYPES_ERROR:
        return {
          ...state,
          isFetching: false,
          error: action.error
        };
      default:
        return state;
    }
  };
  
  export default haplotypesReducer;