import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import isNil from 'lodash/isNil';
import { getHaplotypes } from '../../../../actions/haplotypes';
import { getPhenotypes } from '../../../../actions/phenotypes';
import { Container, Row } from 'reactstrap';
import { Dashboard } from './components/Dashboard';
import HaplotypeEditor from '../HaplotypeEditor';
import { ManualVariantEntryEditor } from './components/ManualVariantEntryEditor';
import "../../../../styles/content.scss"; 

export class DashboardVariants extends Component {
    constructor(props) {
      super(props);
  
      this.state = {
        editing: null,
        activeTab: 'haplotypes',
        haplotypes: [],
        phenotypes: [],
      };

      this.toggleTab = this.toggleTab.bind(this);
    }

    componentDidMount () {
      this.onPageChange();
    }

    setHaplotypes = haplotypes => {
      this.setState({ haplotypes });
    };

    setPhenotypes = phenotypes => {
      this.setState({ phenotypes });
    }
  
    onPageChange = () => {
        this.loadData();
      }

    loadData = () => {
      if (this.state.activeTab === 'haplotypes') {
        this.props
        .getHaplotypes({ page: this.state.currentPage })
        .then(action => {
          this.setHaplotypes(action.payload.data);
      })}

      if (this.state.activeTab === 'phenotypes') {
        this.props
        .getPhenotypes({ page: this.state.currentPage })
        .then(action => {
          this.setPhenotypes(action.payload.data);
      })}
    };

    toggleTab (tab) {
      if (this.state.activeTab !== tab) {
        this.setState({activeTab: tab}, () => this.onPageChange())
      }
    }

    handleClickOnRow = (e, index) => {
      this.setState({editing: index})
    }

    toggleEditor = (editor) => {
      this.setState({editing: editor})
    }

    closeEditing = () => {
      this.setState({editing: null})
    }

    render () {
      const { haplotypes, phenotypes, editing, activeTab } = this.state;
      let display;

      if (isNil(editing)) {
        display = <Dashboard
                    haplotypes={haplotypes}
                    phenotypes={phenotypes}
                    toggleTab={this.toggleTab}
                    activeTab={activeTab}
                    toggleEditor={this.toggleEditor}
                    handleClickOnRow={this.handleClickOnRow}/>
      } else {
        if (editing !== 'newVariant' && activeTab === 'haplotypes') {
          display = <HaplotypeEditor
                      haplotypes={haplotypes}
                      editing={editing}
                      closeEditing={this.closeEditing} />
        }

        if (editing === "newVariant") {
          display = <ManualVariantEntryEditor
                      closeEditing={this.closeEditing} />
        }
      }
      return (
        <Container>
          <Row>
            {display}
          </Row>
        </Container>
      )
    }
  }

const mapStateToProps = state => {
  return {
    haplotypes: state.haplotypes,
    phenotypes: state.phenotypes,
  };
};

export default withRouter(
  connect(
    mapStateToProps,
    {
      getHaplotypes,
      getPhenotypes
    }
  )(DashboardVariants)
);