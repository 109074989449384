import {
    PHENOTYPES_REQUEST,
    PHENOTYPES_SUCCESS,
    PHENOTYPES_ERROR
  } from '../actions/phenotypes';
  
  const initState = {
    data: [],
    isFetching: false,
    error: false
  };
  
  const phenotypesReducer = (state = initState, action) => {
    switch (action.type) {
      case PHENOTYPES_REQUEST:
        return {
          ...state,
          error: false,
          isFetching: true
        };
      case PHENOTYPES_SUCCESS:
        return {
          ...state,
          isFetching: false,
          data: action.payload.data
        };
      case PHENOTYPES_ERROR:
        return {
          ...state,
          isFetching: false,
          error: action.error
        };
      default:
        return state;
    }
  };
  
  export default phenotypesReducer;